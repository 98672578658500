/**
 * src/Services/Chargeurs/chargeur.foodtruck.js
 *
 * Charge les données d'un foodtruck
 */
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken,
    setIdentifiedUserToken
} from "../storage.service";
import {
    net_fetch_type_evaluable,
    net_fetch_evenement,
    net_refresh_token,
    net_fetch_foodtruck
} from "../network.action.service";
import {db, update_foodtruck, update_type_evaluable} from "../database.service";
import {connect_app} from "../connecteur.app";

const charge_foodtruck = async (foodtruck_id, evenement_id) => {
    let anonymous_user_token = getAnonymousUserToken();
    let foodtruck_response;

    if ( navigator.onLine ){
        foodtruck_response = await net_fetch_foodtruck(foodtruck_id, evenement_id ,anonymous_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === foodtruck_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setAnonymousUserToken(refresh_response.data.token);
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
            }else{
                let is_connected = await connect_app();
                if ( ! is_connected ){
                    console.log('non connecté ?')
                    return {};
                }
                console.log('connecté');
            }

            anonymous_user_token = getAnonymousUserToken();
            foodtruck_response = await net_fetch_foodtruck(foodtruck_id, evenement_id ,anonymous_user_token);
        }
        if ( ! foodtruck_response.ok ){
            // ni 401, ni ok
            return {};
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_foodtruck(db,foodtruck_id,foodtruck_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = foodtruck_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return foodtruck_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.foodtruck.where({foodtruck_id: foodtruck_id}).first()
                    .then( (foodtruck_item) => {
                        let data = foodtruck_item.data;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            foodtruck_response = await net_fetch_foodtruck(foodtruck_id, evenement_id, anonymous_user_token);

            if ( ! foodtruck_response.ok ){
                return {};
            }

            return foodtruck_response.data;
        }
    }
}

export { charge_foodtruck };