/**
 * src/Services/Chargeurs/chargeur.evenement.js
 *
 * Charge les données publiques d'un évènement
 */
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken, getEvenementId,
    setAnonymousRefreshToken, setAnonymousUserToken,
} from "../storage.service";
import {
    net_fetch_animations,
    net_refresh_token,
    net_fetch_evenement
} from "../network.action.service";
import {db, update_evenement} from "../database.service";
import {connect_app} from "../connecteur.app";

// comme on ne peut pas bootstraper le router, on doit décorer les accesseurs
// avec le bootstrap

const charge_evenement = async (evenement_id) => {
    if ( ! evenement_id ){
        evenement_id = getEvenementId();
    }
    let anonymous_user_token = getAnonymousUserToken();
    let evenement_response;

    if ( navigator.onLine ){
        evenement_response = await net_fetch_evenement(evenement_id,anonymous_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === evenement_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setAnonymousUserToken(refresh_response.data.token);
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
            }else{
                let is_connected = await connect_app();
                if ( ! is_connected ){
                    console.log('non connecté ?')
                    return {};
                }
                console.log('connecté');
            }

            anonymous_user_token = getAnonymousUserToken();
            evenement_response = await net_fetch_evenement(evenement_id,anonymous_user_token);
        }
        if ( ! evenement_response.ok ){
            // ni 401, ni ok
            return {};
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_evenement(db,evenement_id,evenement_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = evenement_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return evenement_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.evenement.where({evenement_id: evenement_id}).first()
                    .then( (evenement_item) => {
                        let data = evenement_item.evenement;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            evenement_response = await net_fetch_evenement(evenement_id,anonymous_user_token);

            if ( ! evenement_response.ok ){
                return {};
            }

            return evenement_response.data;
        }
    }
};

export { charge_evenement };