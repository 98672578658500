// festivalSxm2024/Router/router.js
// fichier à ajouter en tant que routeur pour écraser le comportement de routage par défaut

import {createBrowserRouter, defer} from "react-router-dom";

// utilise les pages exportées en composition et les route à des chemins
import {Pages} from '../Page';
import {charge_presentation} from "../../../Services/Chargeurs/chargeur.presentation_evenement";

import {charge_animation} from "../../../Services/Chargeurs/chargeur.animation";
import {charge_evenement} from "../../../Services/Chargeurs/chargeur.evenement";
import {getEvenementId} from "../../../Services/storage.service";
import {charge_type_evaluable} from "../../../Services/Chargeurs/chargeur.type_evaluable";
import {charge_foodtruck} from "../../../Services/Chargeurs/chargeur.foodtruck";
import {charge_questionnaire} from "../../../Services/Chargeurs/chargeur.questionnaire";

import {NetworkAccessDecorator} from "../../../Services/NetworkAccessDecorator";
import {RoutingErrorHandler} from "../../../Components/ErrorHandler/RoutingErrorHandler";
import {NetworkLoader} from "../../../Services/Chargeurs/NetworkLoader";
import {charge_liste_des_evaluations_identifie} from "../../../Services/Chargeurs/chargeur.liste.evaluations";
import {charge_ownspace} from "../../../Services/Chargeurs/chargeur.ownspace";
import {charge_evenement_agenda_for_date} from "../../../Services/Chargeurs/chargeur.evenement_agenda_for_date";
import {charge_evenement_agenda_for_id} from "../../../Services/Chargeurs/chargeur.evenement_agenda_for_id";

const { PageConnexion,
    PageEspaceMembre,
    PageHome,
    PageAnimation,
    PageStand,
    PageFoodtruck,
    PageQuestionnaire,
    PageEvaluable,
    PageDeconnexion,
    PageAgenda,
    PageCarte,
    PageEvenementAgenda
} = Pages;

// ! TODO vérifer s'il est possible de traduire les routes ?
const Router = createBrowserRouter(
    [
        {
            path: "/",
            element: <PageHome />,
            loader: async () => {
                const evenement_id = getEvenementId();
                // let data = await charge_evenement(evenement_id);
                let data = await NetworkAccessDecorator(charge_evenement, evenement_id);
                return defer({data});
            }
        },
        {
            path: "/animation",
            element: <PageAnimation />
        },
        {
            path: "/animation/:animation_id",
            element: <PageAnimation />,
            loader: async ( {request, params} ) => {
                const {animation_id} = params;
                let data = await NetworkAccessDecorator(charge_animation,animation_id);
                return defer({data});
            }
        },
        {
            path: "/stand/:type_evaluable_id",
            element: <PageStand />,
            loader: async ( {request, params} ) => {
                const {type_evaluable_id} = params;
                const evenement_id = getEvenementId();
                let data = await NetworkAccessDecorator(charge_type_evaluable,type_evaluable_id, evenement_id);
                return defer({data});
            }
        },
        {
            path: "/foodtruck/:foodtruck_id",
            element: <PageFoodtruck />,
            loader: async ( {request, params} ) => {
                const {foodtruck_id} = params;
                const evenement_id = getEvenementId();
                let data = await NetworkAccessDecorator(charge_foodtruck,foodtruck_id, evenement_id);
                return defer({data});
            }
        },
        // {
        //     path: "/questionnaire/:questionnaire_id/evaluable/:evaluable_id",
        //     element: <PageQuestionnaire />,
        //     loader: async ( {request, params} ) => {
        //         const {questionnaire_id, evaluable_id} = params;
        //         const evenement_id = getEvenementId();
        //         let data = await NetworkAccessDecorator(charge_questionnaire,questionnaire_id, evaluable_id, evenement_id);
        //         return defer({data});
        //     }
        // },
        {
            path: "/questionnaire/:questionnaire_id/competiteur/:competiteur_id",
            element: <PageQuestionnaire />,
            loader: async ( {request, params} ) => {
                const {questionnaire_id, competiteur_id} = params;
                const evenement_id = getEvenementId();
                let data = await NetworkAccessDecorator(charge_questionnaire,questionnaire_id, competiteur_id, evenement_id);
                return defer({data});
            }
        },
        {
            path: "/questionnaire/:questionnaire_id",
            element: <PageEvaluable />,
            loader: async ({request,params}) => {
                return await (new NetworkLoader(charge_liste_des_evaluations_identifie, ["questionnaire_id"])).load({request,params});
            },
        },
        {
            path: "/login",
            element: <PageConnexion />
        },
        {
            path: "/logout",
            element: <PageDeconnexion />
        },
        // {
        //     path: "/myspace",
        //     element: <PageEspaceMembre />,
        // },
        {
            path: "/myspace",
            element: <PageEspaceMembre />,
            errorElement: <RoutingErrorHandler />,
            loader: async ({request,params}) => {
                return await (new NetworkLoader(charge_ownspace)).load({request,params});
            }
        },
        {
            path: "/agenda/:daystring",
            element: <PageAgenda />,
            errorElement: <RoutingErrorHandler />,
            loader: async ({request,params}) => {
                return await (new NetworkLoader(charge_evenement_agenda_for_date, ["daystring"])).load({request,params});
            },
        },
        {
            path: "/carte/:lat/:lon/:nom",
            loader: ({params}) => {
                return {lat: params.lat, lon: params.lon, nom: params.nom};
            },
            element: <PageCarte />,
        },
        {
            path: "/evenement-agenda/:evenement_agenda_id",
            element: <PageEvenementAgenda />,
            errorElement: <RoutingErrorHandler />,
            loader: async ({request,params}) => {
                return await (new NetworkLoader(charge_evenement_agenda_for_id, ["evenement_agenda_id"])).load({request,params});
            },
        },
        {
            path: "*",
            element: <PageHome />,
            loader: async () => {
                const evenement_id = getEvenementId();
                // let data = await charge_evenement(evenement_id);
                let data = await NetworkAccessDecorator(charge_evenement, evenement_id);
                return defer({data});
            }
        }

    ]
);

export { Router };