/**
 * ./src/Services/Chargeurs/chargeur.liste.evaluations.js
 *
 * Charge la liste des évaluations associées à un utilisateur pour un questionnaire particulier
 *
 * Contexte :
 * --------
 * Les évaluations anonymes ne pré-existent pas, elles sont construires au fil de l'eau.
 * La dépendance fonctionnelle est la suivante : (questionnaire_id, user_id) -> (evaluation_id)
 * On a déjà user_id via le token, il nous manque questionnaire_id pour identifier
 * comlétement les instances d'évaluables associées qaux évalautions et questionnaires
 *
 */
import { getEvenementId, getIdentifiedUserToken} from "../storage.service";
import {db, update_evaluations } from "../../Services/database.service";
import {
    net_fetch_liste_evaluations,
    net_fetch_liste_questionnaires,
    net_refresh_token
} from "../network.action.service";
import { getIdentifiedRefreshToken,
    setIdentifiedRefreshToken,
    setIdentifiedUserToken} from "../storage.service";
import {AccessDeniedError} from "../../Exceptions/AccessDeniedError";

const charge_liste_des_evaluations_pour_utilisateur_identifie = async (questionnaire_id, user_token) =>
{
    let liste_evaluation_response;

    if ( navigator.onLine ){
        liste_evaluation_response = await net_fetch_liste_evaluations(questionnaire_id, user_token);

        if ( 401 === liste_evaluation_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                setIdentifiedUserToken(refresh_response.data.token);
            }else{
                throw new AccessDeniedError('/login');
            }

            user_token = getIdentifiedUserToken();
            liste_evaluation_response = await net_fetch_liste_evaluations(questionnaire_id,user_token);
        }
        if ( ! liste_evaluation_response.ok ){
            throw new AccessDeniedError('/login');
        }

        if ( db ){
            return new Promise( (resolve,reject) => {
                update_evaluations(db, questionnaire_id,liste_evaluation_response.data)
                    .catch( err => console.log(err))
                    .finally( () => {
                        let data_to_resolve = liste_evaluation_response.data;
                        resolve(data_to_resolve);
                    })
            });
        }else{
            return liste_evaluation_response.data;
        }
    }else{
        // offline
        if( db ){
            return new Promise( (resolve,reject) => {
                db.evaluations.where({questionnaire_id: questionnaire_id}).first()
                    .then( (liste_evaluation_item) => {
                        const evaluations = liste_evaluation_item.evaluations;
                        resolve(evaluations);
                    })
            })
        }else{
            // on tente un hit cache sur le service worker
            liste_evaluation_response = await net_fetch_liste_evaluations(questionnaire_id, user_token);

            if ( ! liste_evaluation_response.ok ){
                return {};
            }

            return liste_evaluation_response.data;
        }
    }
}

const charge_liste_des_evaluations_identifie = async (questionnaire_id) =>
{
    let liste_evaluations_reponse;
    let user_token = getIdentifiedUserToken();

    if ( user_token ){
        liste_evaluations_reponse = await charge_liste_des_evaluations_pour_utilisateur_identifie(questionnaire_id, user_token);

        // process
        return liste_evaluations_reponse;
    }

    // on n'autorise pas l'accès aux ressources supposés privées pour les anonymes

    // en utlisant defer, on peut indistinctement throw ou rejeter une promessse
    // return new Promise( (resolve, reject) => reject('login'));
    throw new AccessDeniedError('/login');

}

export { charge_liste_des_evaluations_identifie };