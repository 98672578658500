import {getAnonymousUserToken} from "./storage.service";
import {connect_app} from "./connecteur.app";

// const InitializationService = new Promise((resolve,reject) => {
//
//     let anonymous_user_token = getAnonymousUserToken();
//
//     if (!anonymous_user_token){
//         connect_app().then( (result) =>{
//             if (result){
//                 resolve(result);
//             }else{
//                 reject(result);
//             }
//         })
//     }else{
//         resolve(true);
//     }
// });

const InitializationService = async () => {

    let anonymous_user_token = getAnonymousUserToken();

    if (!anonymous_user_token) {
        try {
            return await connect_app();
        } catch (e) {
            return false;
        }

    }

    return true;
}


export { InitializationService };