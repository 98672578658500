/**
 * ./src/Services/Chargeurs/chargeur.evenement_agenda.today.js
 *
 * Charge la liste des évènements-agenda inscrit à l'agenda du chef
 *
 */
import {
    getEvenementId,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken,
    setIdentifiedRefreshToken, setIdentifiedUserToken
} from "../storage.service";
import {
    net_fetch_evenement_agenda_for_date,
    net_fetch_evenement_agenda_for_today,
    net_refresh_token
} from "../network.action.service";
import {db, update_evenement_agenda_for_date, update_evenement_agenda_today} from "../database.service";


const charge_evenement_agenda_for_date_pour_utilisateur_identifie = async (date_string, user_token=null) => {
    let evenement_agendas_response;
    let evenement_id;

    if ( navigator.onLine ){
        evenement_agendas_response = await net_fetch_evenement_agenda_for_date(date_string);
        if ( 401 === evenement_agendas_response.status ){
            // on refresh
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                setIdentifiedUserToken(refresh_response.data.token);
            }else{
                throw new Error('login');
            }

            user_token = getIdentifiedUserToken();
            evenement_agendas_response = await net_fetch_evenement_agenda_for_date(date_string);
        }
        if( ! evenement_agendas_response.ok ){
            return {};
        }

        evenement_id = getEvenementId();
        if ( db ){
            return new Promise( (resolve, reject) => {
               update_evenement_agenda_for_date( db, evenement_id, date_string, evenement_agendas_response.data)
                   .catch( err => console.log(err) )
                   .finally( () => {
                       let data_to_resolve = evenement_agendas_response.data;
                       resolve(data_to_resolve);
                   })
            });
        }else{
            return evenement_agendas_response.data;
        }
    }else{
        // offline on attaque le cache

        if ( db ){
            return new Promise( (resolve, reject) => {
                db.evenement_agenda.where({evenement_id: evenement_id, date: date_string}).first()
                    .then( (evenement_agenda_item) => {
                        const evenement_agenda_data = evenement_agenda_item.data;
                        resolve(evenement_agenda_data);
                    });
            })
        }else{
            // on check le service worker cache
            evenement_agendas_response = await net_fetch_evenement_agenda_for_date(date_string);

            if ( ! evenement_agendas_response ){
                return {};
            }

            return evenement_agendas_response.data;
        }
    }
}

const charge_evenement_agenda_for_date = async (date_string) => {
    let liste_evenement_agenda_response;
    let user_token = getIdentifiedUserToken();

    if( user_token ){
        // les exceptions sont catched à l'intérieur de la fonction
        // et on se sert de la dimension exception ici pour apporter de l'information
        // on catch pas pour permettre le retour sur la dimension exception : (Login)
        liste_evenement_agenda_response = await charge_evenement_agenda_for_date_pour_utilisateur_identifie(date_string);

        return liste_evenement_agenda_response;
    }

    // on assume qu'il est impossible pour un utilisateur qui ne soit pas un chef d'avoir accès à son agenda

    throw new Error('login');

}

export {charge_evenement_agenda_for_date};