const API_URL = "https://admin.chefprolink.com/api";
const ROOT_URL = "https://admin.chefprolink.com";
const IMAGE_URL = "https://admin.chefprolink.com/image"


const APP_USER = "app.festivalsxm.2024@groupescr.fr";
const APP_PWD = "-CFBz#WOF(FRGf1Va{RB";

const APP_THEME = "festivalSxm2024";


const ANONYMOUS_USER = "a_user.festivalsxm.2024@groupescr.fr";
const ANONYMOUS_PWD = "ka$fLEO$u&%Yqls1sK27";

const EVENEMENT_ID = "3";

export { API_URL, ROOT_URL, IMAGE_URL, APP_USER, APP_PWD, APP_THEME, ANONYMOUS_PWD, ANONYMOUS_USER,EVENEMENT_ID }