import {Await, useAsyncValue, useLoaderData} from "react-router-dom";
import {Suspense, useEffect, useReducer, useState} from "react";
import LoadingComponent from "../../Elements/loading.component";
import {SplashImage} from "../../../Bibliotheque/Composants/Metier/SplashImage";
import {CoordonneesEvaluable} from "../../../Bibliotheque/Composants/Metier/CoordonneesEvaluable";
import {NomEvaluable} from "../../../Bibliotheque/Composants/Metier/NomEvaluable";
import {useTranslation} from "react-i18next";
import {useOnlineState} from "../../../Providers/onlinestatequeryable.provider";
import {useSyncToServer} from "../../../Providers/SynchronizationGlobalProvider";
// import {QuestionStarRateRange} from "../../../Bibliotheque/Composants/Metier/QuestionRateRange";
import {QuestionnaireStateFactory} from "../../../Services/DTOStack";
import {build_evaluationDTO_from_questionnaire_state} from "../../../DTO/evaluation_dto";

const QuestionStarRateRange = ({questionNumber, competiteur_id, representation, presentation, dispatcher, sectionNumber, ...component_data}) => {

    const { min, max } = representation;
    const  length = max - min +1;

    // const valeur = presentation_data.valeur;
    const valeur = presentation?.valeur;

    const { t, i18n } = useTranslation();

    const handle_intitule = (component_data) => {
        const translatedIntitule = component_data?.translatedIntitule ?? {fr: '__intitule fr__', en: '__intitule en__'};
        const lang = i18n.language;
        return translatedIntitule[lang];
    }

    const handleChangeValue = (event) => {
        dispatcher({
            type:'setValeur',
            payload: {
                section_number: sectionNumber,
                question_number: questionNumber,
                valeur: event.target.value
            }});
    }

    const display_stars = (min,max,valeur) => {
        let input_array = [];
        valeur = parseInt(valeur);
        for (let i=min; i<=max; i++){
            input_array.push(
                (<input onChange={handleChangeValue} type={`radio`}
                        key={i}
                        name={`q-${questionNumber}-${competiteur_id}`} className={`mask mask-star bg-accent`}
                        value={i}
                        checked={valeur===i}/>)
            );
        }
        return input_array;
    }

    return (
        <div className={`flex flex-col text-normal my-2 rounded-xl`}>
            <div className={`font-medium`}><span>{handle_intitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
            <div className={`rating space-x-2`}>
                {
                    display_stars(min,max,valeur).map( input => input)
                }
            </div>
        </div>
    );
};

const PresentationQuestionnaireFinale = (props) => {
    // const splash_data = props.splash_data;

    return (
        <div className={`w-full max-w-3xl grid justify-start grid-cols-2 m-auto`}>
            <h1 className={`text-3xl text-primary-content font-extrabold col-start-1 col-end-3`} {...props} >Finale des restaurateurs</h1>
        </div>
    );
}

const ControleurEvaluationFinale = (props) => {
    const {t,i18n} = useTranslation();

    const { clickHandle } = props;
    return (
        <div className={`flex flex-row flex-wrap justify-start w-full max-w-3xl m-auto`}>
            <button className={`w-full sm:w-64 bg-primary-content text-primary border-primary-content text-base font-medium hover:bg-accent hover:border-accent hover:text-neutral-content btn`}
                    onClick={() => clickHandle()}>{t('UI.Boutons.evaluer', i18n.language)}</button>
        </div>
    );
}

const resolveDefaultResponse = (component_name) => {
    const defaultResponseMapping = {
        QuestionTexte: '',
        QuestionRange: 1,
    }

    return defaultResponseMapping[component_name];
}

const enrich_questionnnaire_data_with_presentation_data = (questionnaire_data, current_section = -1) =>
{

    let questionnaire_data_to_return = questionnaire_data;

    // Ajoute les données de présentation aux données du serveur
    let sections = questionnaire_data?.sections?? [{questions: []}];

    for ( let i = 0; i<sections.length; i++){

        let questions = sections[i]?.questions ?? [{reponses: []}];


        for ( let j = 0; j<questions.length; j++){
            let valeur_reponse = questionnaire_data.sections[i].questions[j].reponses ? questionnaire_data.sections[i].questions[j].reponses[0]?.valeur : null;
            valeur_reponse = valeur_reponse ?? resolveDefaultResponse(questionnaire_data.sections[i].questions[j].representation.component);

            questionnaire_data_to_return.sections[i].questions[j] = {
                ...questionnaire_data.sections[i].questions[j],
                presentation: {
                    requis : questionnaire_data.sections[i].questions[j].requis,
                    // les données ne sont pas valides mais elles ne sont pas invalides à l'initialisation
                    valide : true,
                    displayInvalide: false,
                    // valide: questionnaire_data.sections[i].questions[j].requis ? valeur_reponse !== '' : true,
                    valeur: valeur_reponse,
                }
            };
        }
    }

    return questionnaire_data_to_return;

}

const initialQuestionnaireState = ({questionnaire_data, last_questionnaire_dtos}) => {

    let initial_state = {
        questionnaire_data: {
            sendBacks: {}
        },
        current_section_is_loading: false,
        // numéro de la section qui s'affiche
        current_section: 0,
        // flag de synchro suivi par l'effet questionnaire
        synchronize_with_server: false,
    };

    initial_state.questionnaire_data = enrich_questionnnaire_data_with_presentation_data(questionnaire_data);

    // puis on modifie les données par les données à jour de la présentation

    if ( last_questionnaire_dtos && Object.keys(last_questionnaire_dtos).length > 0 ) {
        // on reconstruit le questionnaire depuis toutes les sections valides enregistrées

        let enriched_state = (new QuestionnaireStateFactory()).buildFromQuestionnaireDTOs(last_questionnaire_dtos, initial_state);

        initial_state.questionnaire_data = { ...initial_state.questionnaire_data, ...enriched_state.questionnaire_data };

    }

    return initial_state;
}

const questionnaireStateReducer = (state, action) => {
    const { payload, type } = action;

    let next_state = {...state};

    let questions = [];
    let nouvelle_valeur = null;
    let section_number = null;
    let question_number  = null;

    switch( type ) {
        case 'setValeur' :

            nouvelle_valeur = payload.valeur;
            section_number = payload.section_number;
            question_number = payload.question_number;

            // on reset le displayInvalide des questions de la section
            questions = state.questionnaire_data.sections[section_number].questions;
            for (let i = 0; i < questions.length; i++) {
                next_state.questionnaire_data.sections[section_number].questions[i].presentation.displayInvalide = false;
            }

            const nouvelle_valeur_validation =
                state.questionnaire_data.sections[section_number].questions[question_number].requis ?
                    nouvelle_valeur !== '' : true;

            next_state.questionnaire_data.sections[section_number].questions[question_number].presentation.valide = nouvelle_valeur_validation;
            next_state.questionnaire_data.sections[section_number].questions[question_number].presentation.valeur = nouvelle_valeur;

            return next_state;
    }

}

const QuestionnaireFinale = (props) => {

    const {evaluation} = props;
    const { isOnline } = useOnlineState();
    const { pushDTO, setSyncFlag, findQuestionnaireDTOs } = useSyncToServer();
    const instance_name = evaluation.competiteur.instance_name;
    let matches = instance_name.match(/[^0123456789]+ (\d+)/);
    const numero_plat = matches[1] ?? undefined;

    const questionnaire_data = evaluation.questionnaire;
    questionnaire_data.sendBacks = {query__competiteur_id: evaluation.competiteur.id};
    let last_questionnaire_dtos = findQuestionnaireDTOs(questionnaire_data?.id, evaluation?.competiteur?.id);

    const [questionnaireState,dispatchQuestionnaireState] =
        useReducer(questionnaireStateReducer,
            {questionnaire_data: questionnaire_data, last_questionnaire_dtos: last_questionnaire_dtos},
            initialQuestionnaireState
            );

    const question_data = questionnaireState.questionnaire_data.sections[0].questions[0];
    const presentation = question_data.presentation;
    const representation = question_data.representation;
    const translatedIntitule = {'fr': `${question_data.translatedIntitule.fr} ${numero_plat}`,
        'en': `${question_data.translatedIntitule.en} ${numero_plat}`};

    const {validation} = props;
    useEffect( () => {
        if (validation){
            const dto = build_evaluationDTO_from_questionnaire_state(questionnaireState);
            pushDTO(dto);
        }
    }, [validation])


    return (
      <QuestionStarRateRange questionNumber={0} sectionNumber={0} dispatcher={dispatchQuestionnaireState}
            presentation={presentation}
                             representation={representation}
                             translatedIntitule={translatedIntitule}
                             competiteur_id={evaluation.competiteur.id}
      />
    );
}

const EvaluationFinale = (props) => {
    const { setSyncFlag, syncFlag } = useSyncToServer();
    let data = useAsyncValue();
    const {t, i18n} = useTranslation();
    const [ validation, setValidation ] = useState(false);
    let evaluations = [];
    for( let prop_name in data ){
        if(isNaN(parseInt(prop_name))){
            continue;
        }
        evaluations.push(data[prop_name]);
    }
    // pour chaque évaluations on affiche le questionnaire qui se limite à être une question range
    // chaque questionnaire gère indépendamment sa syncrho via leur propre DTO
    // à la validation, on force la synchro des questionnaires.

    const {handleClose} = props;


    const clickHandle = () => {
        setValidation(true);
        setSyncFlag(true);
        handleClose();
        // set les questionnaire en synchro
        // et sort.
    }

    useEffect(() => {
        if ( !syncFlag && validation){
            setValidation(false);
        }
    }, [syncFlag, validation]);

    return (<>
            {
                evaluations.map( (evaluation, index) => (<QuestionnaireFinale key={index} evaluation={evaluation} validation={validation}/>))
            }
            <div className={`flex flex-row flex-wrap justify-start w-full max-w-3xl m-auto mb-12`}>
                <button
                    className={`w-full sm:w-64 bg-primary-content text-primary border-primary-content text-base font-medium hover:bg-accent hover:border-accent hover:text-neutral-content btn`}
                    onClick={() => clickHandle()}>{t('UI.Boutons.valider', i18n.language)}</button>
            </div>
        </>
    );

}

const ModaleEvaluationFinale = ({affichageEvaluation, handleClose, ...props}) => {

    return (
        <div
            className={`absolute top-0 left-0 ${affichageEvaluation ? "translate-y-64" : "translate-y-[100vh]"} transition-transform ease-in-out duration-500 w-full modale-evaluation z-20`}>
            <div className={`w-full max-w-3xl mx-auto bg-base-200 rounded-t-2xl p-4 h-full flex flex-col overflow-auto`}>
                <div className={`flex flex-row gap-4 w-full`}>
                    <button className="btn btn-circle btn-primary" onClick={() => handleClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                    <NomEvaluable className={`col-start-1 col-end-3 text-primary font-normal`} nom_evaluable={``}/>
                </div>
                <EvaluationFinale handleClose={handleClose}/>
            </div>
        </div>
    );
};

const StartEvaluationFinaleFactory = (props) => {
    const {key} = props;
    const loaded = useLoaderData();

    const [affichageEvaluation, setAffichageEvaluation] = useState(false);

    // en mode défensif si le composant est utilisé en dehors d'un
    // suspense pour éviter de casser

    const toggleAffichageEvaluation = (event) => {
        setAffichageEvaluation(!affichageEvaluation);
    }

    return (
        <Suspense fallback={<LoadingComponent/>} key={key}>
            <Await resolve={loaded.data}>
                { loaded?.data?.projection?.gallery[0]?.path ? (<SplashImage image_path={loaded.data.projection.gallery[0].path}/>) : (<></>) }
                <div className={`w-full px-2 lg:px-0 flex flex-col justify-start space-y-10`}>
                    <PresentationQuestionnaireFinale splash_data={loaded.data.splash_data} />
                    {/*<PresentationEvaluable splash_data={loaded.data.splash}/>*/}
                    <CoordonneesEvaluable nom="Finale des restaurateurs" lieu={loaded.data.projection.lieux[0]}/>

                    <ControleurEvaluationFinale clickHandle={toggleAffichageEvaluation}/>
                    <ModaleEvaluationFinale affichageEvaluation={affichageEvaluation} handleClose={toggleAffichageEvaluation}/>
                </div>
            </Await>
        </Suspense>
    );
}

export {StartEvaluationFinaleFactory}