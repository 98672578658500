import {useTranslation} from "react-i18next";
import {TimeService} from "../../../Services/time.service";

const ControleursEvaluation = ({status_evaluation, className, clickHandle, questionnaire, ...props}) => {

    const {t, i18n} = useTranslation();

    const status_map = {
        ouverte: {
            label: t('UI.Boutons.evaluer', i18n.language),
        },
        en_cours: {
            label: t('UI.Boutons.continuer', i18n.language),
        },
        terminee: {
            label: t('UI.Boutons.revoirevaluation', i18n.language),
        }
    }

    const questionnaire_date_fermeture = (new TimeService()).convertDateTimeStringToJSDateTimeObjet(questionnaire.date_fermeture);
    const evaluation_date_ouverture = (new TimeService()).convertDateTimeStringToJSDateTimeObjet(questionnaire.splash.dateEvaluation);
    const date_now = new Date();


    let disabled = (questionnaire_date_fermeture < date_now || evaluation_date_ouverture > date_now) ? 'btn-disabled' : '';
    disabled = '';

    return (
        <div className={`flex flex-row flex-wrap justify-start w-full max-w-3xl m-auto`}>
            <button className={`w-full sm:w-64 bg-primary-content text-primary border-primary-content text-base font-medium hover:bg-accent hover:border-accent hover:text-neutral-content btn ${disabled}`}
                    onClick={() => clickHandle()}>{status_map[status_evaluation].label}</button>
        </div>
    );

}

export {ControleursEvaluation}