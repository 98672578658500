/**
 * src/Services/connecteur.app.js
 *
 * Contient le code pour connecter l'app et créer un utilisateur anonyme de cette app
 */
import {net_create_anonymous, net_login_anonymous} from "./network.action.service";
import {ANONYMOUS_USER, ANONYMOUS_PWD, EVENEMENT_ID} from "../Config/index";


import {
    setAnonymousRefreshToken,
    setAnonymousUserEmail,
    setAnonymousUserPassword,
    setAnonymousUserToken,
    setEvenementId
} from "./storage.service";

const connect_app = async() => {

    // l'utilisateur anonyme est défini dans la config
    // let create_anonymous_response = await net_create_anonymous();
    //
    // if ( ! create_anonymous_response.ok ){
    //     console.log('creation anonyme non ok');
    //     return false;
    // }

    // let anonymous_email = create_anonymous_response.data.email;
    // let anonymous_password = create_anonymous_response.data.password;
    // let evenement_id = create_anonymous_response.data.evenement_id;

    // let anonymous_email = ANONYMOUS_USER;
    // let anonymous_password = ANONYMOUS_PWD;
    // let evenement_id = EVENEMENT_ID;


    setAnonymousUserEmail(ANONYMOUS_USER);
    setAnonymousUserPassword(ANONYMOUS_PWD);
    setEvenementId(EVENEMENT_ID);

    let login_anonymous_user_response = await net_login_anonymous();

    if ( ! login_anonymous_user_response.ok ){
        console.log("login anonyme non ok");
        return false;
    }

    console.log("login anonyme ok");

    let anonymous_user_token = login_anonymous_user_response.data.token;
    let anonymous_refresh_token = login_anonymous_user_response.data.refresh_token;

    setAnonymousUserToken(anonymous_user_token);
    setAnonymousRefreshToken(anonymous_refresh_token);

    return true;
}

export { connect_app };