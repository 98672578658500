/**
 * src/Services/Chargeurs/chargeur.animation.js
 *
 * Charge les animations associées à l'app
 */
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken,
    setIdentifiedUserToken
} from "../storage.service";
import {net_fetch_animation, net_fetch_evenement, net_refresh_token} from "../network.action.service";
import {db, update_animation} from "../database.service";
import {connect_app} from "../connecteur.app";

const charge_animation_pour_utilisateur_identifie = async (animation_id) => {
    let user_token = getIdentifiedUserToken();
    let animation_reponse;
    // return Promise
    if ( navigator.onLine ){
        // on requête la ligne
        animation_reponse = await net_fetch_animation(animation_id,user_token);

        if ( 401 === animation_reponse.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if ( refresh_response.ok ){
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
                setIdentifiedUserToken(refresh_response.data.token);
            }else{
                // si le refresh d'un utilisateur identifié ne fonctionne pas,
                // il faut proposer le login
                throw Error('login');
            }

            user_token = getIdentifiedUserToken();
            animation_reponse = await net_fetch_evenement(animation_id,user_token);
        }
        if ( ! animation_reponse.ok ){
            // ni 401, ni ok
            return {};
        }

        // si db, on stocke
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_animation(db,animation_id,animation_reponse.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = animation_reponse.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return animation_reponse.data;
        }
    }else{
        // si db on get db
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.animation.where({animation_id: animation_id}).first()
                    .then( (animation_item) => {
                        let data = animation_item.animation;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            animation_reponse = await net_fetch_animation(animation_id,user_token);

            if ( ! animation_reponse.ok ){
                return {};
            }

            return animation_reponse.data;
        }
    }
}

const charge_animation_pour_utilisateur_anonyme = async (animation_id) => {
    let anonymous_user_token = getAnonymousUserToken();
    let animation_response;

    if ( navigator.onLine ){
        animation_response = await net_fetch_animation(animation_id,anonymous_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === animation_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setAnonymousUserToken(refresh_response.data.token);
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
            }else{
                let is_connected = await connect_app();
                if ( ! is_connected ){
                    console.log('non connecté ?')
                    return {};
                }
                console.log('connecté');
            }

            anonymous_user_token = getAnonymousUserToken();
            animation_response = await net_fetch_animation(animation_id,anonymous_user_token);
        }
        if ( ! animation_response.ok ){
            // ni 401, ni ok
            return {};
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_animation(db,animation_id,animation_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = animation_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return animation_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.animation.where({animation_id: animation_id}).first()
                    .then( (animation_item) => {
                        let data = animation_item.animation;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            animation_response = await net_fetch_animation(animation_id,anonymous_user_token);

            if ( ! animation_response.ok ){
                return {};
            }

            return animation_response.data;
        }
    }
}

const charge_animation = async (animation_id) => {
    let animation_reponse;

    if ( getIdentifiedUserToken() ){
        try {
            animation_reponse = await charge_animation_pour_utilisateur_identifie(animation_id);
        }catch(e){
            // Need relog !
            animation_reponse = {};
        }
        return animation_reponse;
    }
    if ( getAnonymousUserToken() ){
        // charge présentation d'un utilisateur anonyme
        animation_reponse = await charge_animation_pour_utilisateur_anonyme(animation_id);
        return animation_reponse
    }
    // l'app n'a pas encore connecté
    // on la connecte
    if ( navigator.onLine ){
        let is_connected = await connect_app();
        if ( ! is_connected ){
            return {};
        }

        let anonymous_user_token = getAnonymousUserToken();

        let animation_reponse = await net_fetch_animation(animation_id,anonymous_user_token);

        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_animation(db,animation_id,animation_reponse.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = animation_reponse.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return animation_reponse.data;
        }
    }
    // si on est pas connecté et qu'on est pas en ligne,
    // on envoie un objet vide
    return {};
};

export { charge_animation };