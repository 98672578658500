import {useNavigate, useRouteError, redirect} from "react-router-dom";
import {setRedirectionUrl} from "../../Services/storage.service";

const RoutingErrorHandler = () => {
    const error = useRouteError();
    const navigate = useNavigate()

    window?.href?.location && setRedirectionUrl(window.href.location);

    switch (error?.message){
        case 'other':
            break;
        default:
            navigate('/login');
        break;
    }
};

export {RoutingErrorHandler}