import {MessageCarrierError} from "./MessageCarrierError";

class AccessDeniedError extends MessageCarrierError
{
    constructor(redirect_url, redirect_flag = true, message_string = 'UI.Erreurs.reserve', message_type= 'info') {
        super(message_string, message_type);
        this.redirect_url = redirect_url;
        this.redirect_flag = !!redirect_flag;
    }

    // l'url de redirection après l'access denied
    get redirection_url()
    {
        return this.redirect_url;
    }

    // doit-on propager une url de redirection dans le cas d'un login fructueux?
    get with_redirect()
    {
        return this.redirect_flag;
    }

}

export {AccessDeniedError};