/**
 * src/Services/Chargeurs/chargeur.questionnaire.js
 *
 * Charge les données d'un questionnaire
 */
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken, getEvenementId,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken,
    setIdentifiedUserToken
} from "../storage.service";
import {
    net_fetch_type_evaluable,
    net_fetch_evenement,
    net_refresh_token,
    net_fetch_foodtruck, net_fetch_questionnaire
} from "../network.action.service";
import {db, update_questionnaire, update_questionnaire_identifie, update_type_evaluable} from "../database.service";
import {connect_app} from "../connecteur.app";
import {AccessDeniedError} from "../../Exceptions/AccessDeniedError";

const charge_questionnaire_pour_utilisateur_enregistre = async (questionnaire_id, competiteur_id, evenement_id) =>
{
    let identified_user_token = getIdentifiedUserToken();
    let questionnaire_response;

    if ( navigator.onLine ){
        questionnaire_response = await net_fetch_questionnaire(questionnaire_id, competiteur_id, evenement_id ,identified_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === questionnaire_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setIdentifiedUserToken(refresh_response.data.token);
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
            }else{
                // go login
                //  throw ??
                return { navigate: '/login' }
            }

            identified_user_token = getIdentifiedUserToken();
            questionnaire_response = await net_fetch_questionnaire(questionnaire_id, competiteur_id, evenement_id ,identified_user_token);
        }
        if ( ! questionnaire_response.ok ){
            // ni 401, ni ok
            return {};
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_questionnaire_identifie(db,questionnaire_id,competiteur_id, questionnaire_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = questionnaire_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return questionnaire_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.questionnaire_identifie.where({questionnaire_id: questionnaire_id, evaluable_id: competiteur_id}).first()
                    .then( (questionnaire_item) => {
                        let data = questionnaire_item.data;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            questionnaire_response = await net_fetch_questionnaire(questionnaire_id, competiteur_id, evenement_id, identified_user_token);

            if ( ! questionnaire_response.ok ){
                return {};
            }

            return questionnaire_response.data;
        }
    }
}

const charge_questionnaire = async (questionnaire_id, competiteur_id, evenement_id) => {

    evenement_id = evenement_id ?? getEvenementId();

    let identified_user_token = getIdentifiedUserToken();
    if ( identified_user_token ){
        // récupération du questionnaire pour un utilisateur enregistré
        return charge_questionnaire_pour_utilisateur_enregistre(questionnaire_id, competiteur_id, evenement_id);
    }

    let anonymous_user_token = getAnonymousUserToken();
    let questionnaire_response;

    if ( navigator.onLine ){
        questionnaire_response = await net_fetch_questionnaire(questionnaire_id, competiteur_id, evenement_id ,anonymous_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === questionnaire_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setAnonymousUserToken(refresh_response.data.token);
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
            }else{
                // est-ce qu'on analyse le status de la requête de refresh pour déterminer ce qu'on en fait ?
                throw new AccessDeniedError('/',false);
            }

            anonymous_user_token = getAnonymousUserToken();
            questionnaire_response = await net_fetch_questionnaire(questionnaire_id, competiteur_id, evenement_id ,anonymous_user_token);
        }
        if ( ! questionnaire_response.ok ){
            // on serait en 403 éventuellement par défaut, mais à tester.
            throw new AccessDeniedError('/',false);
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_questionnaire(db,questionnaire_id,competiteur_id,questionnaire_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = questionnaire_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return questionnaire_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.questionnaire.where({questionnaire_id: questionnaire_id, evaluable_id: competiteur_id}).first()
                    .then( (questionnaire_item) => {
                        let data = questionnaire_item.data;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            questionnaire_response = await net_fetch_questionnaire(questionnaire_id, evenement_id, anonymous_user_token);

            if ( ! questionnaire_response.ok ){
                return {};
            }

            return questionnaire_response.data;
        }
    }
}

export { charge_questionnaire };