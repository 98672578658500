/**
 * src/Services/Chargeurs/chargeur.type_evaluable.js
 *
 * Charge le type d'évaluable transmis as type_evaluable_id
 */
import {
    getAnonymousRefreshToken,
    getAnonymousUserToken,
    getIdentifiedRefreshToken,
    getIdentifiedUserToken, setAnonymousRefreshToken, setAnonymousUserToken,
    setIdentifiedRefreshToken,
    setIdentifiedUserToken
} from "../storage.service";
import {net_fetch_type_evaluable, net_fetch_evenement, net_refresh_token} from "../network.action.service";
import {db, update_type_evaluable} from "../database.service";
import {connect_app} from "../connecteur.app";

const charge_type_evaluable = async (type_evaluable_id, evenement_id) => {
    let anonymous_user_token = getAnonymousUserToken();
    let type_evaluable_response;

    if ( navigator.onLine ){
        type_evaluable_response = await net_fetch_type_evaluable(type_evaluable_id, evenement_id ,anonymous_user_token);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === type_evaluable_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getAnonymousRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setAnonymousUserToken(refresh_response.data.token);
                setAnonymousRefreshToken(refresh_response.data.refresh_token);
            }else{
                let is_connected = await connect_app();
                if ( ! is_connected ){
                    console.log('non connecté ?')
                    return {};
                }
                console.log('connecté');
            }

            anonymous_user_token = getAnonymousUserToken();
            type_evaluable_response = await net_fetch_type_evaluable(type_evaluable_id, evenement_id ,anonymous_user_token);
        }
        if ( ! type_evaluable_response.ok ){
            // ni 401, ni ok
            return {};
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_type_evaluable(db,type_evaluable_id,type_evaluable_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = type_evaluable_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return type_evaluable_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.type_evaluable.where({type_evaluable_id: type_evaluable_id}).first()
                    .then( (type_evaluable_item) => {
                        let data = type_evaluable_item.animation;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            type_evaluable_response = await net_fetch_type_evaluable(type_evaluable_id, evenement_id, anonymous_user_token);

            if ( ! type_evaluable_response.ok ){
                return {};
            }

            return type_evaluable_response.data;
        }
    }
}

export { charge_type_evaluable };