import {NetworkAccessDecorator} from "../NetworkAccessDecorator";
import {charge_liste_des_evaluations_identifie} from "./chargeur.liste.evaluations";
import {defer, redirect} from "react-router-dom";
import {setFlashMessage, setFlashStyle, setRedirectionUrl} from "../storage.service";
import {AccessDeniedError} from "../../Exceptions/AccessDeniedError";

class NetworkLoader {
    constructor(data_loader_function, request_parameters_name){
        this.loader_function = data_loader_function;
        this.request_parameters_name = Array.isArray(request_parameters_name) ? request_parameters_name : [request_parameters_name];
    }

    async load({request,params}){
        let request_parameters_value = this.request_parameters_name.map( (parameter_name) => params[parameter_name] );
        let data = {};
        try{
            data = await NetworkAccessDecorator(this.loader_function,...request_parameters_value);

            // if (data?.strategy_of_access){
            //     throw new Error('apply_strat',{
            // }

        }catch(e){
            // defaut strategy
            if ( e instanceof AccessDeniedError ){
                e.with_redirect && setRedirectionUrl(window.location.href);
                e.message_string.length && setFlashMessage(e.message_string);

                switch(e.message_type){
                    case 'info':
                    default:
                        setFlashStyle('bg-info text-info-content');
                }

                return redirect(e.redirection_url);
            }

            // comportement par défaut
            return redirect('/login');
        }

        return defer({data});
    }

}

export {NetworkLoader};